<div class="custom-toast">
    <div
        class="toast-icon"
        [ngClass]="{
            'success-toast': toastType === 'toast-success',
            'error-toast': toastType === 'toast-error',
            'info-toast': toastType === 'info',
        }"
    >
        <app-svg-icon [iconPath]="getIconPath()"></app-svg-icon>
    </div>
    <div class="toast-message">
        <span class="toast-message-content">{{ message }}</span>
    </div>
</div>
