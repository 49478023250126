import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { delay, filter } from 'rxjs/operators'
import { Userpilot } from 'userpilot'
import { Objects } from '../../../server/src/shared-with-client/object-definitions'
import { EnvironmentService } from './environment.service'
import { LoggedInUser, ParseService } from './parse.service'

@Injectable({
    providedIn: 'root',
})
export class UserpilotService {
    private isInitialized = false
    private subscription: Subscription | undefined

    constructor(
        private parseService: ParseService,
        private environmentService: EnvironmentService,
        private router: Router
    ) {}

    identify(user: LoggedInUser) {
        if (!user) {
            return
        }

        if (!this.isInitialized) {
            this.parseService.reportUIError(new Error('User pilot is not initialized'), 'UserpilotService.identify', '')
            return
        }

        try {
            const metadata: Pick<Objects.UserPilot.Metadata, 'email' | 'name' | 'first_name' | 'last_name' | 'signup_date'> = {
                name: user.firstName + ' ' + user.lastName,
                email: user.username,
                first_name: user.firstName,
                last_name: user.lastName,
                signup_date: user.createdAt,
            }
            Userpilot.identify(user.id, metadata)
        } catch (error) {
            this.parseService.reportUIError(error, 'UserpilotService.identify', JSON.stringify({ userID: user.id }))
        }
    }

    private initialize() {
        if (!this.environmentService.userpilotToken) {
            throw new Error('No userpilotToken found')
        }

        Userpilot.initialize(this.environmentService.userpilotToken)
    }

    init() {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }

        this.subscription = this.parseService.user.pipe(delay(0)).subscribe((user) => {
            try {
                if (!user) {
                    if (this.isInitialized) {
                        try {
                            // can cause window.userpilot.destroy is not a function. (In 'window.userpilot.destroy()', 'window.userpilot.destroy' is undefined) error
                            Userpilot.destroy()
                        } catch (err) {}
                        this.isInitialized = false
                    }
                    return
                }

                if (!this.isInitialized) {
                    this.initialize()
                    this.isInitialized = true
                }

                this.identify(user)
            } catch (error) {
                this.parseService.reportUIError(error, 'UserpilotService.init', JSON.stringify({ userID: user?.id }))
            }
        })

        this.router.events.pipe(filter((ev) => ev instanceof NavigationEnd)).subscribe(() => {
            if (this.isInitialized) {
                try {
                    Userpilot.reload()
                } catch (error) {
                    this.parseService.reportUIError(error, 'UserpilotService.init.router.events', '')
                }
            }
        })
    }
}
