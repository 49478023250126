import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { ColorPickerService } from 'ngx-color-picker'
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { ColorService } from 'src/app/account/brand/color.service'
import { Establishment } from 'src/app/core/parse.service/establishment'
import { ParseService } from 'src/app/parse.service'
import { ColorPickerDialogComponent, Data as ColorPickerDialogData } from 'src/app/shared/dialog/color-picker-dialog/color-picker-dialog.component'
import { DialogService } from 'src/app/shared/dialog/dialog.service'
import { MlsListingImageItem } from 'src/app/shared/image-picker-dialog/image-picker-dialog.component'
import { ChiliColorAll } from 'src/app/shared/types'
import { logDebug } from 'src/utils'
import { Objects } from '../../../../../server/src/shared-with-client/object-definitions'
import { OnboardingForm } from '../../../../../server/src/shared-with-client/object-definitions/OnboardingForm'
import { Utils } from '../../../../../server/src/shared-with-client/utils'

export type Form = {
    fields: Objects.OnboardingForm.Form.Fields
    establishmentId: string | undefined
}

@Component({
    selector: 'app-onboarding-form',
    templateUrl: './onboarding-form.component.html',
    styleUrls: ['./onboarding-form.component.scss'],
    standalone: false,
})
export class OnboardingFormComponent implements OnInit, OnDestroy {
    @HostBinding('class') classes = 'mat-typography'

    @Input() isSaving = false
    @Output() formChange = new EventEmitter<Form>()

    form: Form = OnboardingFormComponent.createEmptyForm()
    phoneFormControl = new UntypedFormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)])

    private readonly destroy$ = new Subject<void>()

    private imageURLMap: { [key: string]: string | undefined } = {}
    isFormLoaded = false

    imagesFormGroup = new FormGroup({
        headshot: new FormControl<Objects.Image.Parse | null>(null),
        companyLogo: new FormControl<Objects.Image.Parse | null>(null),
    })

    static createEmptyForm(): Form {
        return {
            establishmentId: undefined,
            fields: {
                firstName: {
                    fieldName: 'firstName',
                    label: 'First Name',
                    type: 'text',
                    text: undefined,
                },
                lastName: {
                    fieldName: 'lastName',
                    label: 'Last Name',
                    type: 'text',
                    text: undefined,
                },
                phone: {
                    fieldName: 'phone',
                    label: 'Phone',
                    type: 'text',
                    text: undefined,
                },
                email: {
                    fieldName: 'email',
                    label: 'Email Address',
                    type: 'text',
                    text: undefined,
                },
                website: {
                    fieldName: 'website',
                    label: 'Website',
                    type: 'text',
                    text: undefined,
                },
                license: {
                    fieldName: 'license',
                    label: 'License',
                    type: 'text',
                    text: undefined,
                },
                headshot: {
                    fieldName: 'headshot',
                    label: 'Headshot',
                    type: 'image',
                    imageId: undefined,
                },
                companyLogo: {
                    fieldName: 'companyLogo',
                    label: 'Company Logo',
                    type: 'image',
                    imageId: undefined,
                },
                primaryColor: {
                    fieldName: 'primaryColor',
                    label: 'Primary Color',
                    type: 'color',
                    color: undefined,
                },
                secondaryColor: {
                    fieldName: 'secondaryColor',
                    label: 'Secondary Color',
                    type: 'color',
                    color: undefined,
                },
            },
        }
    }

    constructor(
        private colorService: ColorService,
        private colorPickerService: ColorPickerService,
        private dialogService: DialogService,
        private dialog: MatDialog,
        private parseService: ParseService
    ) {}

    async ngOnInit(): Promise<void> {
        this.parseService.selectedEstablishment
            .pipe(
                filter((establishment): establishment is Establishment => {
                    return establishment !== undefined
                }),
                takeUntil(this.destroy$)
            )
            .subscribe(async (establishment) => {
                function setTextValue(field: OnboardingForm.Form.Field.Value.Text, text: string | undefined) {
                    if (text && typeof text !== 'string') {
                        logDebug('onboarding field not string')
                        return
                    }

                    if (text) {
                        field.text = text
                    }
                }

                const setImageValue = (field: OnboardingForm.Form.Field.Value.Image, image: Objects.Image.Parse | undefined | null) => {
                    if (image) {
                        field.imageId = image.id
                        if (field.fieldName === 'headshot' || field.fieldName === 'companyLogo') {
                            this.imagesFormGroup.controls[field.fieldName].setValue(image)
                        }

                        this.addImageToCache(image)
                    }
                }

                function setColorValue(field: OnboardingForm.Form.Field.Value.Color, color: Objects.Color.Any | undefined) {
                    if (color) {
                        field.color = color
                    }
                }

                try {
                    this.form = OnboardingFormComponent.createEmptyForm()
                    this.form.establishmentId = establishment.id

                    const [onboardingForm, payer] = await Promise.all([
                        this.parseService.getOnboardingForm(establishment.id),
                        this.parseService.getEstablishmentPayer(establishment.id),
                    ])

                    if (onboardingForm) {
                        setTextValue(this.form.fields['email'], onboardingForm.get('email'))
                        setTextValue(this.form.fields['firstName'], onboardingForm.get('firstName'))
                        setTextValue(this.form.fields['lastName'], onboardingForm.get('lastName'))

                        const phoneDigits = Utils.User.getDigits(onboardingForm.get('phone'))
                        this.phoneFormControl.setValue(phoneDigits)
                        setTextValue(this.form.fields['website'], onboardingForm.get('website'))

                        setImageValue(this.form.fields['headshot'], onboardingForm.get('headshot'))
                        setImageValue(this.form.fields['companyLogo'], onboardingForm.get('companyLogo'))

                        setColorValue(this.form.fields['primaryColor'], onboardingForm.get('primaryColor'))
                        setColorValue(this.form.fields['secondaryColor'], onboardingForm.get('secondaryColor'))
                    } else if (payer) {
                        setTextValue(this.form.fields['email'], payer.get('username'))
                        setTextValue(this.form.fields['firstName'], payer.get('firstName'))
                        setTextValue(this.form.fields['lastName'], payer.get('lastName'))

                        const phoneDigits = Utils.User.getDigits(payer.get('phone'))
                        this.phoneFormControl.setValue(phoneDigits)
                    }
                } catch (err) {
                    this.parseService.reportUIError(err, 'OnboardingFormComponent.ngOnInit.selectedEstablishment.subscribe', JSON.stringify({ establishment }))
                } finally {
                    this.formChange.next(this.form)
                    this.isFormLoaded = true
                }
            })

        this.phoneFormControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
            if (this.form.fields.phone.type !== 'text') {
                logDebug('phone field type not text')
                return
            }

            if (val && typeof val === 'string') {
                const parsedPhoneValue = Utils.User.formatStringToPhone(val.trim())
                const phone: string = Utils.User.formatPhoneToString(parsedPhoneValue)
                this.form.fields.phone.text = phone
            } else {
                this.form.fields.phone.text = ''
            }

            this.formChange.emit(this.form)
        })

        this.imagesFormGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((formValue) => {
            if (formValue.headshot) {
                this.form.fields.headshot.imageId = formValue.headshot.id
            } else if (formValue.companyLogo) {
                this.form.fields.companyLogo.imageId = formValue.companyLogo.id
            }
            this.formChange.emit(this.form)
        })
    }

    ngOnDestroy(): void {
        this.destroy$.next()
    }

    async onColorSelected(chiliColor: ChiliColorAll, field: Objects.OnboardingForm.Form.Field.Value.Color) {
        if (field.type !== 'color') {
            logDebug('field type should be color') // should never happen
            return
        }
        const color: Objects.Color.RGBA = {
            r: chiliColor.rgb.r,
            g: chiliColor.rgb.g,
            b: chiliColor.rgb.b,
            a: 1,
        }

        field.color = color
        this.formChange.emit(this.form)
    }

    getColorHex(field: Objects.OnboardingForm.Form.Field.Value.Color): string {
        let color: Objects.Color.Any = {
            a: 1,
            r: 255,
            g: 255,
            b: 255,
        }
        if (field.type !== 'color') {
            logDebug('field type should be color') // should never happen
        } else if (field.color) {
            color = field.color
        }

        return this.colorService.colorObjectToHex(color, this.colorPickerService)
    }

    isParseImage(image: Objects.Image.Parse | MlsListingImageItem): image is Objects.Image.Parse {
        const IMAGE_COLLECTION: Objects.Image.Collection = 'Image'
        return 'className' in image && image.className === IMAGE_COLLECTION
    }

    private addImageToCache(image: Objects.Image.Parse | MlsListingImageItem) {
        this.imageURLMap[image.id] = this.isParseImage(image) ? (image.get('thumbURL') ?? image.get('url')) : image.url
    }

    onColorInputClicked(field: Objects.OnboardingForm.Form.Field.Value.Color) {
        if (this.isSaving) {
            return
        }

        const data: ColorPickerDialogData = {
            initialColorHex: this.getColorHex(field),
        }
        const dialogOptions = this.dialogService.getDialogOptions(data, false)
        dialogOptions.width = '325px'
        dialogOptions.position = { top: '5%' }

        const dialog = this.dialog.open(ColorPickerDialogComponent, dialogOptions)

        dialog.componentInstance.colorSelected.pipe(takeUntil(dialog.afterClosed())).subscribe((color) => {
            this.onColorSelected(color, field)
        })
    }
}
