import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ParseService } from 'src/app/parse.service'
import { AbstractDialogComponent } from 'src/app/shared/dialog/dialog.class'
import { UnknownObject } from '../../../../../server/src/shared-with-client/types'
import { Form } from '../form/onboarding-form.component'

export type OnboardingDialogData = UnknownObject

@Component({
    selector: 'app-onboarding-dialog',
    templateUrl: './onboarding-dialog.component.html',
    styleUrls: ['./onboarding-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class OnboardingDialogComponent extends AbstractDialogComponent<OnboardingDialogData, OnboardingDialogComponent> {
    @HostBinding('class') classes = 'mat-typography'

    isSaving = false
    form: Form | undefined

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: OnboardingDialogData,
        public dialogRef: MatDialogRef<OnboardingDialogComponent>,
        private parseService: ParseService
    ) {
        super(data, dialogRef)
    }

    onFormChange(ev: Form) {
        this.form = ev
    }

    async onSaveClicked() {
        try {
            if (!this.form) {
                return
            }
            const establishmentId = this.form.establishmentId
            if (!establishmentId) {
                return
            }

            this.isSaving = true
            await this.parseService.createOnboardingForm(this.form.fields, establishmentId)
            this.dialogRef.close()
        } catch (err) {
            this.parseService.reportUIError(err, 'OnboardingDialogComponent.onSaveClicked', JSON.stringify(this.form))
        } finally {
            this.isSaving = false
        }
    }
}
