import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component } from '@angular/core'
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr'
import { SvgIconComponent } from '../svg-icon/svg-icon.component'

@Component({
    selector: 'app-custom-toast',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, SvgIconComponent],
})
export class CustomToastComponent extends Toast {
    title: string = ''
    message: string = ''
    toastType: 'toast-success' | 'toast-error' | 'info' = 'info'

    constructor(
        protected toastrService: ToastrService,
        public toastPackage: ToastPackage
    ) {
        super(toastrService, toastPackage)
        this.title = toastPackage.title ?? ''
        this.message = toastPackage.message ?? ''

        if (toastPackage.toastType === 'toast-success' || toastPackage.toastType === 'toast-error' || toastPackage.toastType === 'info') {
            this.toastType = toastPackage.toastType
        } else {
            this.toastType = 'info'
        }
    }
    getIconPath(): string {
        switch (this.toastType) {
            case 'toast-success':
                return '/assets/img/new-designs/icons/toast-success-icon.svg'
            case 'toast-error':
                return '/assets/img/new-designs/icons/toast-error-icon.svg'
            default:
                return '/assets/img/new-designs/icons/toast-error-info.svg'
        }
    }
}
