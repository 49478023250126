import { Injectable } from '@angular/core'
import { Subscription } from 'rxjs'
import { delay } from 'rxjs/operators'
import { LoggedInUser, ParseService } from './parse.service'

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        __insp?: any[]
    }
}

export interface UserDetails {
    id: string
    firstName: string
    lastName: string
    email: string
}

@Injectable({
    providedIn: 'root',
})
export class InspectletService {
    private _initialized = false
    private userSubscription: Subscription | undefined

    constructor(private parseService: ParseService) {}

    init() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe()
        }

        this.userSubscription = this.parseService.user.pipe(delay(0)).subscribe((user) => {
            if (!user) {
                return
            }
            try {
                this.identify(user)
            } catch (error) {
                this.parseService.reportUIError(error, 'InspectletService.constructor', JSON.stringify({ userID: user.id }))
            }
        })
    }

    identify(user: LoggedInUser) {
        if (!window.__insp) {
            return
        }

        const userDetails: UserDetails = {
            id: user.id,
            email: user.username,
            firstName: user.firstName,
            lastName: user.lastName,
        }

        window.__insp.push(['identify'], userDetails.email)

        window.__insp.push(['tagSession'], {
            userId: userDetails.id,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            email: userDetails.email,
        })
    }
}
