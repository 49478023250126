import { Routes } from '@angular/router'
import { AuthGuardService } from './auth-guard.service'
import { LatestVersionGuardService } from './latest-version-guard.service'
import { OnboardingGuardService } from './onboarding-guard.service'

export const AppRoutes: Routes = [
    {
        path: 'home-valuation',
        loadChildren: () => import('./home-valuation/home-valuation.module').then((m) => m.HomeValuationModule),
    },
    {
        path: 'login',
        loadChildren: () => import('./login-wrapper/login-wrapper.routes').then((m) => m.routes),
    },
    {
        path: 'ref',
        loadChildren: () => import('./user-referral/user-referral.module').then((m) => m.UserReferralModule),
    },
    {
        path: 'signup',
        loadChildren: () => import('./sign-up-wrapper/sign-up-wrapper.routes').then((m) => m.routes),
    },
    {
        path: 'reset-password/:token',
        loadChildren: () => import('./reset-password-wrapper/reset-password.route').then((m) => m.routes),
    },
    {
        path: 'resetPassword',
        redirectTo: 'reset-password',
    },
    {
        path: '',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
        canActivateChild: [LatestVersionGuardService],
        canActivate: [OnboardingGuardService],
        canLoad: [AuthGuardService],
        data: { roles: ['User', 'ProFulfillment Customer', 'WP_Ops Member'] },
    },
    {
        path: '**',
        redirectTo: '',
    },
]
