import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'

import { MatSnackBarModule } from '@angular/material/snack-bar'
import { IndeterminateButtonLoadingDirective } from '../../directives/indeterminate-button-loading/indeterminate-button-loading.directive'
import { InvalidSessionDialogComponent } from './invalid-session.component'
import { InvalidSessionService } from './invalid-session.service'

@NgModule({
    declarations: [InvalidSessionDialogComponent],
    providers: [InvalidSessionService],
    imports: [
        CommonModule,
        FormsModule,
        IndeterminateButtonLoadingDirective,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSnackBarModule,
        ReactiveFormsModule,
    ],
    exports: [InvalidSessionDialogComponent],
})
export class InvalidSessionDialogModule {}
