import { OverlayModule } from '@angular/cdk/overlay'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog'
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { IntercomModule } from '@supy-io/ngx-intercom'
import { CookieService } from 'ngx-cookie-service'
import { ToastrModule } from 'ngx-toastr'
import { AppComponent } from './app.component'
import { AppRoutes } from './app.routing'
import { AuthGuardService } from './auth-guard.service'
import { EnvironmentService } from './environment.service'
import { InspectletService } from './inspectlet.service'
import { LatestVersionGuardService } from './latest-version-guard.service'
import { LogRocketService } from './log-rocket.service'
import { OnboardingModule } from './onboarding/onboarding.module'
import { ParseLiveQueryService } from './parse-live-query.service'
import { ParseService } from './parse.service'
import { SentryModule } from './sentry.module'
import { ServerStatusService } from './server-status.service'
import { CustomToastComponent } from './shared/designs-v2/alert-component/alert.component'
import { InvalidSessionDialogModule } from './shared/dialog/invalid-session/invalid-session.module'
import { NotificationService } from './shared/services/notification.service'
import { UserpilotService } from './userpilot.service'
import { UtilsService } from './utils.service'

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            toastComponent: CustomToastComponent,
            timeOut: 5000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
        RouterModule.forRoot(AppRoutes /*, { enableTracing: true }*/),
        IntercomModule.forRoot({
            appId: '', // from your Intercom config, INTERCOM_APP_ID
            updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`,
        }),
        InvalidSessionDialogModule,
        SentryModule.forRoot(),
        MatMomentDateModule,
        OverlayModule,
        OnboardingModule,
    ],
    providers: [
        AuthGuardService,
        CookieService,
        EnvironmentService,
        LatestVersionGuardService,
        LogRocketService,
        ParseLiveQueryService,
        ParseService,
        ServerStatusService,
        UtilsService,
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { maxHeight: '95vh', maxWidth: '95vw', hasBackdrop: true } },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'center',
            },
        },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'LL',
                },
                display: {
                    dateInput: 'LL',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        UserpilotService,
        InspectletService,
        provideHttpClient(withInterceptorsFromDi()),
        NotificationService,
    ],
})
export class AppModule {}
