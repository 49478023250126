import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { ColorPickerModule } from 'ngx-color-picker'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import { ImageSelectionPlaceholderComponent } from 'src/app/shared/image-selection-placeholder/image-selection-placeholder.component'
import { ColorService } from '../account/brand/color.service'
import { ColorPickerDialogModule } from '../shared/dialog/color-picker-dialog/color-picker-dialog.module'
import { IndeterminateButtonLoadingDirective } from '../shared/directives/indeterminate-button-loading/indeterminate-button-loading.directive'
import { ImagePickerDialogComponent } from '../shared/image-picker-dialog/image-picker-dialog.component'
import { InputPhoneModule } from '../shared/input-phone/input-phone.module'
import { OnboardingDialogComponent } from './dialog/onboarding-dialog.component'
import { OnboardingFormComponent } from './form/onboarding-form.component'

@NgModule({
    declarations: [OnboardingDialogComponent, OnboardingFormComponent],
    imports: [
        CommonModule,
        ColorPickerDialogModule,
        ColorPickerModule,
        IndeterminateButtonLoadingDirective,
        ImagePickerDialogComponent,
        InputPhoneModule,
        FormsModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        NgxSkeletonLoaderModule,
        ReactiveFormsModule,
        ImageSelectionPlaceholderComponent,
    ],
    providers: [ColorService],
})
export class OnboardingModule {}
