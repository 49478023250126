import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateChild, NavigationError, NavigationStart, Router, RouterStateSnapshot } from '@angular/router'
import { ServerStatusService } from './server-status.service'

@Injectable()
export class LatestVersionGuardService implements CanActivateChild {
    private willUpdateClient = false
    constructor(
        private serverStatusService: ServerStatusService,
        private router: Router
    ) {
        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationError) {
                console.error('Err', ev.error?.message)
            }
            if (!(ev instanceof NavigationStart)) {
                return
            }
            if (ev.id > 1 && this.serverStatusService.serverHasBeenUpdated) {
                const REPLACE_URL = location.origin + ev.url
                this.willUpdateClient = true
                location.replace(REPLACE_URL)
            }
        })
    }

    async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if (this.serverStatusService.serverHasBeenUpdated && this.willUpdateClient) {
            return false
        }
        return true
    }
}
