import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { NavigationExtras, Router } from '@angular/router'
import { Intercom } from '@supy-io/ngx-intercom'
import { BehaviorSubject, combineLatest, of, onErrorResumeNextWith, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, skip, takeUntil, timeout } from 'rxjs/operators'
import { UnknownObject } from '../../../server/src/shared-with-client/types'
import { environment } from '../environments/environment'
import { InspectletService } from './inspectlet.service'
import { OnboardingDialogComponent, OnboardingDialogData } from './onboarding/dialog/onboarding-dialog.component'
import { ParseService } from './parse.service'
import { DialogService } from './shared/dialog/dialog.service'
import { AnalyticsService } from './shared/services/analytics.service'
import { FeatureFlagService } from './shared/services/feature-flag.service'
import { HubspotChatService } from './shared/services/hubspot-chat.service'
import { PosthogService } from './shared/services/posthog.service'
import { UserpilotService } from './userpilot.service'

type UserPilotEvent =
    | { action: 'open-onboarding-form' }
    | { action: 'router.navigate'; commands: unknown[]; extras: NavigationExtras; posthogOpts?: UnknownObject }

declare global {
    interface Window {
        userPilotEventEmitter?: Subject<UserPilotEvent>
        fbAsyncInit?: () => void
    }
}

@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false,
})
export class AppComponent implements OnInit, OnDestroy {
    isReady = false

    private readonly destroy$ = new Subject<void>()
    private dialogRef: MatDialogRef<unknown> | undefined
    private parseLoaded = new BehaviorSubject<boolean>(false)

    constructor(
        private dialogService: DialogService,
        private inspectletService: InspectletService,
        private dialog: MatDialog,
        private ngZone: NgZone,
        private userpilotService: UserpilotService,
        private parseService: ParseService,
        private hubspotChatService: HubspotChatService,
        private intercom: Intercom,
        private router: Router,
        private posthogService: PosthogService,
        public analyticsService: AnalyticsService,
        private featureFlagService: FeatureFlagService
    ) {
        this.parseService
            .init()
            .then(() => {
                this.parseLoaded.next(true)
            })
            .catch((err) => {})
        // try {
        //     if (window.location.hostname.endsWith(WP_DOMAIN)) {
        //         // https://developer.mozilla.org/en-US/docs/Web/API/Document/domain
        //         // Is deprecated and could fail
        //         // This is introduced for getting the editor URL which should also have a fallback
        //         // should document.domain complain
        //         document.domain = WP_DOMAIN;
        //     }

        // } catch (err) {
        // }
        combineLatest([this.parseLoaded, this.posthogService.flagsLoaded().pipe(distinctUntilChanged(), timeout(5000), onErrorResumeNextWith(of(true)))])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([parseLoaded, flagsLoaded]) => {
                if (parseLoaded && flagsLoaded) {
                    this.isReady = true
                }
            })
    }

    ngOnInit() {
        try {
            // TODO: Remove this directive once the bug is fixed
            /**
             * Temporary code to fix Chrome browser crashing.
             * See: https://issues.chromium.org/issues/335553723?pli=1
             */
            document.addEventListener('DOMNodeInserted', function () {
                const elements = document.querySelectorAll('[aria-owns]')
                elements.forEach((element) => {
                    element.removeAttribute('aria-owns')
                })
            })
        } catch (err) {}
        this.userpilotService.init()
        this.inspectletService.init()
        if (!window.userPilotEventEmitter) {
            window.userPilotEventEmitter = new Subject()
        }

        // Initialize chat
        this.hubspotChatService.resetChat()

        this.parseService.user.pipe(skip(1), debounceTime(200), distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(async (user) => {
            if (user) {
                const tokenResponse = await this.parseService.generateVisitorIdentificationToken()
                if (tokenResponse.token) {
                    this.hubspotChatService.logUser(user.username, tokenResponse.token)
                } else {
                    this.hubspotChatService.resetChat()
                }
            } else {
                this.hubspotChatService.resetChat()
            }
        })

        combineLatest([window.userPilotEventEmitter, this.featureFlagService.isOnboardingControlVariant()])
            .pipe(
                filter(([ev, isOnboardingControlVariant]) => {
                    return ev.action === 'open-onboarding-form' && isOnboardingControlVariant
                }),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                // ngZone is needed since the emitter will be called outside of angular's context
                this.ngZone.run(() => {
                    if (this.dialogRef) {
                        return
                    }

                    const data: OnboardingDialogData = {}
                    const dialogOptions = this.dialogService.getDialogOptions(data, false)
                    dialogOptions.width = '620px'
                    dialogOptions.position = { top: '5%' }

                    this.dialogRef = this.dialog.open(OnboardingDialogComponent, dialogOptions)
                    this.dialogRef.afterClosed().subscribe(() => {
                        this.dialogRef = undefined
                    })
                })
            })
        window.userPilotEventEmitter
            .pipe(
                filter((ev) => ev.action !== 'open-onboarding-form'),
                takeUntil(this.destroy$)
            )
            .subscribe((ev) => {
                // ngZone is needed since the emitter will be called outside of angular's context
                this.ngZone.run(() => {
                    if (ev.action === 'router.navigate') {
                        // {
                        //     action: "router.navigate",
                        //     commands: ["/templates"],
                        //     extras: {
                        //         queryParams: {
                        //             tab: "all",
                        //             search: "Halloween Contest 1"
                        //         }
                        //     }
                        // }
                        this.router.navigate(ev.commands, ev.extras)

                        if (ev.posthogOpts) {
                            const currentPage = this.router.url
                            this.analyticsService.trackUserPilotBannerClicked(currentPage, ev.posthogOpts)
                        }
                    }
                })
            })

        this.intercom.update({
            hide_default_launcher: true,
        })
        // let isProductionHost = true;
        // try {
        //     isProductionHost = document.location.host === 'app.wisepelican.com'
        // } catch (err) {
        //     this.parseService.reportUIError(err, 'AppComponent.ngOnInit.IsProductionHost', '')
        // }

        // if (isProductionHost) {

        //     let section = 0
        //     try {

        //         section = 1
        //         {
        //             window.dataLayer = window.dataLayer || [];
        //             window.dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });

        //             const f = document.getElementsByTagName('script')[0]
        //             const j = document.createElement('script')
        //             j.async = true;
        //             j.src = "https://www.googletagmanager.com/gtm.js?id=GTM-WWKW8N6"
        //             if (f.parentNode) {
        //                 f.parentNode.insertBefore(j, f);
        //             }
        //         }

        //         section = 2
        //         {
        //             // Causes snaptr, pintrk, gtag, fbq to get enabled
        //             const node = document.createElement('script');
        //             node.src = 'https://www.googletagmanager.com/gtag/js?id=AW-706516239';
        //             node.type = 'text/javascript';
        //             node.async = true;
        //             // node.charset = 'utf-8';
        //             document.getElementsByTagName('head')[0].appendChild(node);
        //         }

        //         section = 3
        //         {
        //             window.gtag = (arg1: unknown, arg2: unknown) => {
        //                 if (window.dataLayer) {
        //                     window.dataLayer.push(arguments);
        //                 }
        //             }
        //             window.gtag('js', new Date());
        //             window.gtag('config', 'AW-706516239');
        //         }

        //         section = 4
        //         // Causes uetq to be enabled
        //         {

        //             window.uetq = window.uetq || []
        //             const f = () => {
        //                 const o = { ti: "17450699", q: window.uetq };

        //                 // @ts-ignore
        //                 window.uetq = new UET(o)
        //                 // @ts-ignore
        //                 window.uetq.push("pageLoad")
        //             }
        //             const n = document.createElement("script")
        //             n.src = "//bat.bing.com/bat.js"
        //             n.async = true
        //             // @ts-ignore
        //             n.onload = n.onreadystatechange = () => {
        //                 // @ts-ignore
        //                 const s = n.readyState;
        //                 if (!s || s === 'loaded' || s === 'complete') {
        //                     f()
        //                     // @ts-ignore
        //                     n.onload = n.onreadystatechange = null
        //                 }
        //             }

        //             const i = document.getElementsByTagName('script')[0]
        //             if (i.parentNode) {
        //                 i.parentNode.insertBefore(n, i)
        //             }

        //         }

        //     } catch (err) {
        //         this.parseService.reportUIError(err, 'AppComponent.ngOnInit.IsProductionHost.AddingPixels', JSON.stringify({ section }))
        //     }
        // }

        window.fbAsyncInit = () => {
            FB.init({
                appId: environment.FACEBOOK_APP_ID,
                cookie: true,
                xfbml: true,
                version: 'v21.0',
            })
            FB.AppEvents.logPageView()
        }
        const insertFbJs = (d: Document) => {
            const s = 'script'
            const id = 'facebook-jssdk'
            if (d.getElementById(id)) {
                return
            }
            const fjs = d.getElementsByTagName(s)[0]
            const js = d.createElement(s)
            js.id = id
            js.src = 'https://connect.facebook.net/en_US/sdk.js'

            if (fjs.parentNode) {
                fjs.parentNode.insertBefore(js, fjs)
            }
        }
        insertFbJs(document)
    }

    ngOnDestroy(): void {
        this.destroy$.next()
    }
}
