import { HttpClient } from '@angular/common/http'
import { Injectable, NgZone } from '@angular/core'
import { firstValueFrom } from 'rxjs'
import { ServerStatus } from '../../../server/src/shared-with-client/endpoint-definitions/serverStatus'
import { EnvironmentService } from './environment.service'
import { ParseService } from './parse.service'

@Injectable()
export class ServerStatusService {
    readonly CONTACT_SERVER_INTERVAL = (1000 * 60 * 60) / 10 // 1 Hour divided by 10;
    private lastRefresh = Date.now()
    private _serverHasBeenUpdated = false
    private serverStart = 0

    constructor(
        private httpClient: HttpClient,
        private environmentService: EnvironmentService,
        private parseService: ParseService,
        private ngZone: NgZone
    ) {
        this.init()
    }

    private init() {
        const BASE_PATH: ServerStatus.Path.Base = 'status'
        const SERVER_START_PATH: ServerStatus.Path.ServerStart = 'server-start'

        firstValueFrom(this.httpClient.get<ServerStatus.Response.ServerRestart>(`${this.environmentService.backendUrl}/${BASE_PATH}/${SERVER_START_PATH}`))
            .then((serverStartResponse) => {
                this.serverStart = serverStartResponse.serverStart
            })
            .catch((err) => {})

        this.ngZone.runOutsideAngular(() => {
            const timer = setInterval(async () => {
                try {
                    const user = this.parseService.user.getValue()
                    const duration = ((Date.now() - this.lastRefresh) / 1000 / 60 / 60).toFixed(2)
                    const queries: string[] = [`${user ? 'user=' + user.id : ''}`, `duration=${duration}`].filter((query) => {
                        return !!query
                    })

                    const serverStartResponse = await firstValueFrom(
                        this.httpClient.get<ServerStatus.Response.ServerRestart>(
                            `${this.environmentService.backendUrl}/${BASE_PATH}/${SERVER_START_PATH}?${queries.join('&')}`
                        )
                    )

                    if (serverStartResponse.serverStart > this.serverStart) {
                        this._serverHasBeenUpdated = true
                        clearInterval(timer)
                    }
                } catch {}
            }, this.CONTACT_SERVER_INTERVAL)
        })
    }

    get serverHasBeenUpdated() {
        return this._serverHasBeenUpdated
    }
}
