import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { ChiliColorPickerModule } from '../../chili-color-picker/chili-color-picker.module'
import { ColorPickerDialogComponent } from './color-picker-dialog.component'

@NgModule({
    declarations: [ColorPickerDialogComponent],
    imports: [CommonModule, ChiliColorPickerModule, MatButtonModule, MatDialogModule],
})
export class ColorPickerDialogModule {}
