<p>
    Creating your profile will let you customize any of our templates in seconds. Just select your created Profile from the dropdown when you edit a template
    and these values will populate!
</p>

<div style="display: flex; flex-direction: column; margin: 4px">
    <div style="display: flex; flex-wrap: wrap; justify-content: space-around">
        <ng-container *ngTemplateOutlet="textInput; context: { field: form.fields.firstName }"></ng-container>
        <ng-container *ngTemplateOutlet="textInput; context: { field: form.fields.lastName }"></ng-container>
    </div>
    <div style="display: flex; flex-wrap: wrap; justify-content: space-around">
        <ng-container *ngTemplateOutlet="textInput; context: { field: form.fields.email }"></ng-container>
        <ng-container *ngTemplateOutlet="textInput; context: { field: form.fields.website }"></ng-container>
    </div>
    <div style="display: flex; justify-content: center">
        <mat-form-field style="width: 250px; margin: 16px 4px 4px 4px">
            <mat-label>Phone</mat-label>
            <app-input-phone [formControl]="phoneFormControl" [disabled]="isSaving"></app-input-phone>
            <mat-error>Please enter a 10 digit phone number</mat-error>
        </mat-form-field>
        <!-- <ng-container *ngTemplateOutlet="textInput; context: {field: form.fields.phone}"></ng-container> -->
    </div>
    <div style="display: flex; flex-wrap: wrap; justify-content: space-around">
        <div style="display: flex; flex-wrap: wrap; justify-content: space-around">
            <ng-container
                *ngTemplateOutlet="
                    imageInput;
                    context: { field: form.fields.headshot, control: imagesFormGroup.controls.headshot, category: 'Agent', subcategory: 'headshot' }
                "
            ></ng-container>
            <ng-container
                *ngTemplateOutlet="
                    imageInput;
                    context: { field: form.fields.companyLogo, control: imagesFormGroup.controls.companyLogo, category: 'Branding', subcategory: 'companyLogo' }
                "
            ></ng-container>
        </div>
        <div style="display: flex; flex-wrap: wrap; justify-content: space-around">
            <ng-container *ngTemplateOutlet="colorInput; context: { field: form.fields.primaryColor }"></ng-container>
            <ng-container *ngTemplateOutlet="colorInput; context: { field: form.fields.secondaryColor }"> </ng-container>
        </div>
    </div>
</div>

<ng-template #textInput let-field="field">
    <mat-form-field style="width: 250px; margin: 16px 4px 4px 4px" class="wp-no-padding">
        <mat-label>{{ field.label }}</mat-label>
        <input matInput [(ngModel)]="field.text" [disabled]="isSaving" />
    </mat-form-field>
</ng-template>

<ng-template #imageInput let-control="control" let-field="field" let-category="category" let-subcategory="subcategory">
    <app-image-selection-placeholder
        [image]="control.value ?? undefined"
        [label]="field.label"
        (imageChange)="control.setValue($event)"
        [category]="category"
        [subcategory]="subcategory"
    >
    </app-image-selection-placeholder>
</ng-template>

<ng-template #colorInput let-field="field">
    <div style="margin: 16px 2px 2px 2px; max-width: 300px">
        <div (click)="onColorInputClicked(field)" style="align-self: flex-start" style="cursor: pointer">
            <label>{{ field.label }}</label>
            <div style="width: 100px; height: 100px; border: 1px solid #a9afbb; border-radius: 4px" [ngStyle]="{ 'background-color': getColorHex(field) }">
                <div *ngIf="isFormLoaded && field.color" style="width: 100px; height: 100px"></div>
                <div *ngIf="isFormLoaded && !field.color" style="display: flex; justify-content: center; align-items: center; width: 100px; height: 100px">
                    <mat-icon style="color: #a9afbb">palette</mat-icon>
                </div>
                <ngx-skeleton-loader
                    *ngIf="!isFormLoaded"
                    style="display: block"
                    animation="progress"
                    appearance="line"
                    [theme]="{ height: '98px', width: '98px' }"
                >
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
</ng-template>
